module.exports = {
    banner1_text:'La vida es demasiado corta para pasar tiempo con personas que te quitan la felicidad. Si alguien te quiere en su vida, te hará un lugar.',
    leistungsumfang:'Leistungsumfang',
    leistungsumfang_text:'Actualmente atendemos a más de 100.000 clientes y recibimos buenas críticas',
    leistungsumfang_free:'TRANSFERENCIA GRATUITA',
    home:'página principal',
    about:'sobre',
    order:'Pedido',
    record:'Historial de pedidos',
    user:'mío',
    about_text1:'productos de calidad',
    about_text2:'Más productos',
    order_title:'Pedido de hoy',
    zongzichan:'Activos totales',
    jinridan:'Pedidos de hoy',
    tiyanjin:'Fondo de experiencia',
    jinrishou:'Ingresos de hoy',
    jintuandui:'Comisión de equipo de hoy',
    dianjigou:'Comience el task',
    adianjigou:'submit',
    order_illustrate:'La plataforma no se hace responsable si el dinero se transfiere incorrectamente a su cuenta bancaria. El monto mínimo de retiro es de 50,000 wones.',
    order_record:'Historial de pedidos',
    all:'all',
    undone:'Sin terminar',
    complete:'Completado',
    score:'score',
    benutzer:'user',
    chongzhi:'recarga',
    tixian:'Retiro',
    details:'Detalles de la cuenta',
    password:'Administración de contraseñas',
    password_jy:'Contraseña de transacción',
    notify:'Notificaciones del sistema',
    kefu:'servicio al cliente',
    bank_card:'tarjeta bancaria',
    invite:'Invitar amigos',
    team:'Informe del equipo',
    language:'Seleccionar idioma',
    member:'Actualización de membresía',
    balance:'Saldo de la cuenta',
    quit:'Cerrar sesión',
    invite_text1:'Invitar a amigos para ganar dinero',
    invite_code:'Código de invitación',
    invite_btn:'Enlace de invitación: haga clic para copiar',
    copy_s:'Copia realizada correctamente',
    copy_b:'Error de copia',
    grade:'Nivel de membresía',
    my_money:'Saldo de mi cuenta',
    open_member:'Registrarse para obtener membresía',
    retreat_num:'Número de retiros',
    day:'Día',
    retreat_quota:'Límite de retiro',
    order_num:'Cantidad de pedido',
    profit_scale:'Tasa de comisión',
    member_time:'La membresía es permanente',
    confirm_pay:'Confirmar pago',
    order_sub:'Enviar pedido',
    user_info:'Información del usuario',
    avatar:'avatar',
    username:'nombre de usuario',
    set_up:'Configurar ahora',
    revise_name:'Modificar el nombre',
    username_hint:'Por favor, rellene su nombre de usuario',
    user_account:'Cuenta de usuario',
    add_money:'Recargar',
    add_money_num:'Monto de la recarga',
    add_money_hint1:'1. El importe del pago debe ser coherente con el importe del pedido, de lo contrario no llegará automáticamente',
    add_money_hint2:'2. Si no recibe recargas ni retiros, consulte a su superior o al servicio de atención al cliente para resolver otros problemas',
    add_money_hint3:'Comuníquese con el servicio de atención al cliente para conocer los cargos',
    add_money_record:'Registro de recarga',
    retreat_record:'Registro de retiro',
    retreat_money:'Monto del retiro',
    can_withdraw_money:'Monto que se puede retirar',
    order_number:'SN',
    money:'monto',
    bank:'BANCO',
    transaction_flow:'Número de flujo de transacción',
    Already_paid:'He pagado',
    upload_pay_img:'Subir captura de pantalla del pago',
    order_details:'Detalles de la cuenta',
    old_password:'Contraseña anterior',
    new_password:'Contraseña nueva',
    repeat_password:'Repita la contraseña',
    enter:'Introduzca',
    password_hint:'Recuerde su contraseña. Si olvida su contraseña, comuníquese con el servicio de atención al cliente',
    submit:'Enviar',
    bankCard:'Vincular tarjeta bancaria',
    bank:'Apertura bancaria',
    bank_card_num:'Número de tarjeta bancaria',
    bank_card_name:'Nombre del titular de la tarjeta',
    team:'Informe del equipo',
    today:'Hoy',
    ayer:'Ayer',
    week:'Esta semana',
    scale:'Escala',
    team_people:'Personas del equipo',
    team_order_scale:'Comisión de pedido del equipo',
    open_bank_name:'Nombre de la cuenta',
    phone:'Número de teléfono',
    user_password:'Contraseña',
    log:'Iniciar sesión',
    register:'Registrarse',
    password_qr:'Confirmar contraseña',
    pwd_no_same:'Las contraseñas no coinciden',
    loading:'Cargando',
    freeze:'Congelar',
    pending:'Pendiente',
    order_hao:'Número de pedido',
    order_time:'Hora de recepción del pedido',
    price:'Precio unitario',
    order_total_price:'Monto total del pedido',
    scale:'Comisión',
    level:'Nivel de miembro',
    level_up:'Mejora de miembro',
    pay_way:'Método de recarga',
    money_min:'El monto es demasiado pequeño',
    pay_no:'Seleccione el método de recarga',
// 新加
    team_all:'Todos',
    team_yi:'Primer nivel',
    team_er:'Segundo nivel',
    team_san:'Tercer nivel',
    close_order:'Cancelar pedido',
    shouru:'Ingresos',
    zhichu:'Gastos',
    welcome:'Bienvenido',
// 新加2
    order_kong:'El número de pedido no puede estar vacío',
    quer:'Confirmar',
    quxiao: 'Cancelar',
    qianbaodi: 'Dirección de billetera',
    xingming: 'Nombre',
    bank_chong: 'No se puede vincular repetidamente',
    introduce: 'Presentación de la empresa',
    platform: 'Reglas de la plataforma',
    tixianzhang: 'Cuenta de retiro',
    xuanze: 'Seleccione',
    xiayiye: 'Haga clic para cargar la página siguiente'
}