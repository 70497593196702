import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import { Locale } from 'vant'
import messages from './index'
const lang = localStorage.getItem('lang') || 'en-US'
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import enUS from 'vant/es/locale/lang/en-US';
import esES from 'vant/es/locale/lang/es-ES';
const i18n = createI18n({
  fallbackLocale: 'en-US',
  globalInjection:true,
  legacy: false, // you must specify 'legacy: false' option
  locale: lang,
  messages,
});
  // 更新vant组件库本身的语言变化，支持国际化
  function vantLocales (language) {
    if (language === 'zh-CN') {
      Locale.use(language, zhCN)
    }else if(language === 'en-US'){
      Locale.use(language, enUS)
    }else if(language === 'es-ES'){
      Locale.use(language, esES)
    }
  }

export {i18n,vantLocales}