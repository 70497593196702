import zh from './lang/zh'
import es from './lang/es'
import en from './lang/en'
// // 引入英文语言包
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import enUS from "vant/es/locale/lang/en-US";
import esES from "vant/es/locale/lang/es-ES";

export default {
    'zh-CN': {...zh,...zhCN},
    'en-US': {...en,...enUS},
    'es-ES': {...es,...esES},
}