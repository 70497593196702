import "core-js/modules/es.array.push.js";
import { useI18n } from 'vue-i18n';
import store from '@/store/index';
import { vantLocales } from '@/i18n/i18n';
import { get_lang, is_recharge } from '@/api/api';
export default {
  setup() {
    // 多语言
    const {
      locale
    } = useI18n();
    let lang = localStorage.getItem('lang') || 'jp-jp';
    locale.value = lang;
    vantLocales(lang);
    get_lang().then(res => {
      // console.log('lang',res)
      // setlang()
      let ls = [];
      res.data.forEach(key => {
        ls.push({
          label: key.title,
          value: key.link
        });
      });
      // store.dispatch('changelang',ls)
      store.state.langList = ls;
      // console.log('lang',ls)
    });

    is_recharge().then(res => {
      if (res.open_recharge) {
        return store.dispatch('changeOpenRecharge', true);
      }
      store.dispatch('changeOpenRecharge', false);
    });
  }
};